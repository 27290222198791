<!--设备模块--人员上下级设置页-->
<template>
  <div id="upperAndLowerSetting" ref="upperAndLowerSetting"  v-loading="loading" :element-loading-text="$t('upperAndLowerSetting.a23')">
    <!-- 侧边栏 -->
    <div class="aside">
        <my-navdar :list="list" @select="getMsgFormSon"></my-navdar>
    </div>
    <!-- 右侧内容区域 -->
    <div class="right"  v-if="id" v-loading="main_loading" :element-loading-text="$t('upperAndLowerSetting.a23')">
      <div class="main">
        <my-headertitle>{{ $t('upperAndLowerSetting.a1') }}</my-headertitle>
        <div class="info">
          <span>{{nodeName}}</span>
          <div>
            <span>{{this.$t('upperAndLowerSetting.a2')}}</span>
            <span>{{ nodeId }}</span>
          </div>
        </div>

        <!-- 人员信息表标题 -->
        <my-tabletitle>{{ $t('upperAndLowerSetting.a3') }}</my-tabletitle>
        <!-- 人员信息表格 -->
        <a-table :columns="columnsPerson" :dataSource="listPerson" :row-selection="rowSelection" :pagination="pagination" :rowKey="(record) => record.key"></a-table>

        <!-- 选择上级人员 -->
        <a-button :disabled = "disabledBtn" class="but" type="primary" @click="addUpper">{{ $t('upperAndLowerSetting.a13') }}</a-button>
        <!-- 删除所有人员 -->
        <a-button :disabled = "listUpper.length === 0" class="but" type="primary" @click="deleteAllUpper">{{ $t('upperAndLowerSetting.a16') }}</a-button>
        <!-- 上级人员表标题 -->
        <my-tabletitle>{{this.$t('upperAndLowerSetting.a4')}}</my-tabletitle>
        <!-- 上级人员表格 -->
        <a-table :columns="columnsUpper" :dataSource="listUpper" :pagination="pagination" :rowKey="(record) => record.key">
          <span slot="sendMsg" slot-scope="record">
            <a>{{ record.sendMsg }}</a>
            <a-checkbox :checked="record.sendMsg"></a-checkbox>
          </span>
          <span slot="sendWechatMsg" slot-scope="record">
            <a-checkbox :checked="record.sendWechatMsg"></a-checkbox>
          </span>
          <span slot="delete" slot-scope="text, record">
            <a-button type="primary" @click="() => onDeleteUpper(record)">{{ $t("upperAndLowerSetting.a15") }}</a-button>
          </span>
        </a-table>

        <!-- 选择下级人员 -->
        <a-button :disabled = "disabledBtn" class="but" type="primary" @click="addLower">{{ $t('upperAndLowerSetting.a14') }}</a-button>
        <!-- 删除所有人员 -->
        <a-button :disabled = "listLower.length === 0" class="but" type="primary" @click="deleteAllLower">{{ $t('upperAndLowerSetting.a16') }}</a-button>
        <!-- 下级人员表标题 -->
        <my-tabletitle>{{ $t('upperAndLowerSetting.a5') }}</my-tabletitle>
        <!-- 下级人员表格 -->
        <a-table :columns="columnsLower" :dataSource="listLower" :pagination="pagination" :rowKey="(record) => record.key">
          <span slot="sendMsg" slot-scope="record">
            <a-checkbox :checked="record.sendMsg"></a-checkbox>
          </span>
          <span slot="sendWechatMsg" slot-scope="record">
            <a-checkbox :checked="record.sendWechatMsg"></a-checkbox>
          </span>
          <span slot="delete" slot-scope="text, record">
            <a-button type="primary" @click="() => onDeleteLower(record)">{{ $t("upperAndLowerSetting.a15") }}</a-button>
          </span>
        </a-table>
      </div>  
      <!-- 底部功能栏 -->
      <div class="footer">
        <a-button type="primary" @click="setting">{{ $t('upperAndLowerSetting.a17') }}</a-button>
      </div>
    </div>
    <!-- 无内容显示 -->
    <div class="empty" v-else>
      <a-empty />
    </div>

    <!-- 选择上下级人员对话框 -->
    <a-modal :title="modalTitle" :width="800" centered :visible="visible" @ok="handleOk" @cancel="handleCancel" :destroyOnClose="true" v-drag>
      <my-personChooseModal :personGrId="personGrId" :isUpper="isUpper" :listUpper ="listUpper" :listLower="listLower" @selectedRows="getSelectedRows"></my-personChooseModal>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from '../../components/Title/tabletitle'
import navdar from '../../components/Navdar/verticalnew'
import headerTitle from '../../components/Title/headerTitle'
import personChooseModal from './personChooseModal'
import { getPersonList, getPersons, getpersonData } from '../../api/info'
import { getPersonSuperiorList, updatePersonRankInfo } from '../../api/visitor'
import moment from 'moment'

export default {
  data() {
    return {
      disabledBtn:true,
      modalTitle: this.$t('upperAndLowerSetting.a3'),
      list: [],
      arr: [],
      selectedRow:undefined,
      selectedPersons:undefined,
      pos: 0,
      id: 0,
      personGrId:0,
      siteId:this.$route.query.id,
      loading: false,
      main_loading: false,
      nodeId: 0,//树节点ID
      nodeName: "",//树节点名称
      listPerson:[],
      listUpper:[],
      listLower:[],
      isUpper:false,//是否选择上级人员，否则为下级人员
      visible: false,
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => `${total} ${this.$t("upperAndLowerSetting.a20")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columnsPerson: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          fixed: 'left' ,
        },        
        {
          title: this.$t("upperAndLowerSetting.a6"),
          dataIndex: "managementCode",
          fixed: 'left' ,
          ellipsis: true,
        },
        {
          title: this.$t("upperAndLowerSetting.a7"),
          dataIndex: "name",
          ellipsis: true,
        },
        {
          title: this.$t("upperAndLowerSetting.a8"),
          dataIndex: "belong",
          ellipsis: true,
        },
      ],
      columnsUpper: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          fixed: 'left' ,
        },        
        {
          title: this.$t("upperAndLowerSetting.a6"),
          dataIndex: "superiorCode",
          fixed: 'left' ,
          ellipsis: true,
        },
        {
          title: this.$t("upperAndLowerSetting.a9"),
          dataIndex: "superiorName",
          ellipsis: true,
        },
        {
          title: this.$t("upperAndLowerSetting.a10"),
          dataIndex: "sendMsg",
          ellipsis: true,
          scopedSlots: { customRender: 'sendMsg' },
        },
        {
          title: this.$t("upperAndLowerSetting.a11"),
          dataIndex: "sendWechatMsg",
          ellipsis: true,
          scopedSlots: { customRender: 'sendWechatMsg' },
        },
        {
          title: this.$t("upperAndLowerSetting.a15"),
          dataIndex: "delete",
          fixed: 'right',
          scopedSlots: { customRender: "delete" },
          // width: 100,
        },
      ],
      columnsLower: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          fixed: 'left' ,
        },        
        {
          title: this.$t("upperAndLowerSetting.a6"),
          dataIndex: "personCode",
          fixed: 'left' ,
          ellipsis: true,
        },
        {
          title: this.$t("upperAndLowerSetting.a12"),
          dataIndex: "personName",
          ellipsis: true,
        },
        {
          title: this.$t("upperAndLowerSetting.a10"),
          dataIndex: "sendMsg",
          ellipsis: true,
          scopedSlots: { customRender: 'sendMsg' },
        },
        {
          title: this.$t("upperAndLowerSetting.a11"),
          dataIndex: "sendWechatMsg",
          ellipsis: true,
          scopedSlots: { customRender: 'sendWechatMsg' },
        },
        {
          title: this.$t("upperAndLowerSetting.a15"),
          dataIndex: "delete",
          fixed: 'right',
          scopedSlots: { customRender: "delete" },
          // width: 100,
        },
      ],
    };
  },
  mounted() {
    // 进入页面时调用获取页面左侧列表方法
    this.navderList() 
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          if(selectedRows!=null&&selectedRows.length>0){
            this.disabledBtn = false;
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.selectedRow = selectedRows[0];
            this.personId = selectedRows[0].personId;
            this.getPersonSuperiorList();
          }
          else
          {
            this.disabledBtn = true;
            this.listUpper=[];
            this.listLower=[];
          }
        }    
      }
    }
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {
      this.getPersons();
    },
    p1: function () {
      this.selectList = this.arr.slice(this.p1, this.p2);
    }
  },
  methods: {
    getSelectedRows(msg){
      this.selectedPersons = msg;
      console.log('getSelectedRows msg',this.selectedPersons)
    },
    handleOk(){         
      this.visible = false;
      if(this.isUpper){
        if(this.selectedRow!=null &&this.selectedPersons!=null){
          let personUpper = null;
          for(let i=0;i<this.selectedPersons.length;i++){
            personUpper={
                          dateTime: new moment().format('YYYY-MM-DD HH:mm:ss'),
                          id: 0,
                          memo: "",
                          personCode:this.selectedRow.managementCode,
                          personId:this.selectedRow.personId,
                          personName:this.selectedRow.name,
                          sendMsg:false,
                          sendWechatMsg:false,
                          siteId:this.siteId,
                          superiorCode:this.selectedPersons[i].managementCode,
                          superiorId:this.selectedPersons[i].personId,
                          superiorName:this.selectedPersons[i].name,
                          tenantId:0,
                          flags:0,
                        };
            this.listUpper.push(personUpper);
          }
          console.log('handleOk listUpper',this.listUpper);
        }
      }
      else{
        if(this.selectedRow!=null &&this.selectedPersons!=null){
          let personLower = null;
          for(let i=0;i<this.selectedPersons.length;i++){
            personLower={
                          dateTime: new moment().format('YYYY-MM-DD HH:mm:ss'),
                          id: 0,
                          memo: '',
                          superiorCode:this.selectedRow.managementCode,
                          superiorId:this.selectedRow.personId,
                          superiorName:this.selectedRow.name,
                          sendMsg:false,
                          sendWechatMsg:false,
                          siteId:this.siteId,
                          personCode:this.selectedPersons[i].managementCode,
                          personId:this.selectedPersons[i].personId,
                          personName:this.selectedPersons[i].name,
                          tenantId:0,
                          flags:0,
                        };
            this.listLower.push(personLower);
          }
          console.log('handleOk listLower',this.listLower);
        }
      }
    },
    handleCancel(){
      this.visible = false;
    },
    addUpper(){
      this.visible = true;
      this.isUpper = true;
    },
    addLower(){
      this.visible = true;
      this.isUpper = false;
    },
    deleteAllUpper(){
      this.listUpper=[];
    },
    deleteAllLower(){
      this.listLower=[];
    },
    //删除
    onDeleteUpper(record){
      this.listUpper.splice(record.index,1);
    },
    //删除
    onDeleteLower(record){
      this.listLower.splice(record.index,1);
    },
    // 获取页面左侧列表方法
    navderList() {
      this.loading = true;
      const par = {
        siteGrId: this.$store.getters.sitegrId,
      };      
      getPersonList(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          if(data!=null&&data.length>0){
            let ids=[];
            for(let i=0;i<data.length;i++){
              ids = data[i].id.split("_");
              if(parseInt(ids[0])==this.siteId){
                this.list.length=0;
                if(data[i].children[0]!=null){
                  this.list.push(data[i].children[0]); 
                  this.personGrId=parseInt(data[i].children[0].id);//取全部管理所属ID
                }
                console.log('getPersonList--personGrId',this.personGrId) 
                break;
              }
            }            
          }        
           
          console.log('getPersonList--加载数据：',this.list);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 加载人员数据的方法    
    getPersons() {
      this.main_loading = true; 
      let par = {
        personGrId : this.nodeId,
        siteId :this.siteId,
        managementCode:'',
        personName:'',
      };  
      console.log('getPersons --par',par);
      getpersonData(par)
        .then((res) => {
          this.main_loading = false;
          let { data } = res;
          this.listPerson = data;
          if(this.listPerson!=null && this.listPerson.length>0){
            for(let i=0;i<this.listPerson.length;i++){
              this.listPerson[i].key = i+1;
              if(this.listPerson[i].personGr2!=null&&this.listPerson[i].personGr2.length>0)
                this.listPerson[i].belong = this.listPerson[i].personGr2;
              if(this.listPerson[i].personGr3!=null&&this.listPerson[i].personGr3.length>0)
                this.listPerson[i].belong += "-"+this.listPerson[i].personGr3;
              if(this.listPerson[i].personGr4!=null&&this.listPerson[i].personGr4.length>0)
                this.listPerson[i].belong += "-"+this.listPerson[i].personGr4;
              if(this.listPerson[i].personGr5!=null&&this.listPerson[i].personGr5.length>0)
                this.listPerson[i].belong += "-"+this.listPerson[i].personGr5;
            }
          }
          console.log('getPersons --res',data);
        })
        .catch((err) => {
          this.main_loading = false;
          console.log(err);
        });
    },    
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(key) {
      this.nodeId=parseInt(key);
      this.id = parseInt(key);
      console.log('getMsgFormSon key：',key)
    },
    //获取上下级列表
    getPersonSuperiorList(){      
      this.main_loading = true;   
      console.log('getPersonSuperiorList --siteId',this.siteId);
      console.log('getPersonSuperiorList --personId',this.personId);
      getPersonSuperiorList(this.siteId,this.personId)
        .then((res) => {
          this.main_loading = false;
          let { data } = res;
          if(data!=null){
            this.listUpper=data.upPersons;
            this.listLower=data.downPersons;
          }
          
          console.log('getPersonSuperiorList --res',data);
        })
        .catch((err) => {
          this.main_loading = false;
          console.log(err);
        });
    },
    //设置
    setting() {
      this.updatePersonRankInfo();
    },
    //更新人员上下级关系信息
    updatePersonRankInfo(){
      this.main_loading = true; 
      let par = {
        personId: this.personId,
        downs: this.listLower,
        leaders: this.listUpper,
      }
      console.log('updatePersonRankInfo --par',par);
      updatePersonRankInfo(this.siteId,par)
        .then((res) => {
          this.main_loading = false;
          let { data } = res;
          if(data)
              this.$message.info(this.$t('upperAndLowerSetting.a18')); 
            else
              this.$message.error(this.$t('upperAndLowerSetting.a19'));
          console.log('updatePersonRankInfo --res',data);
        })
        .catch((err) => {
          this.main_loading = false;
          console.log(err);
        });
    }, 
  },
  components: {
    'my-tabletitle': tabletitle,
    'my-navdar': navdar,
    'my-headertitle': headerTitle,
    'my-personChooseModal':personChooseModal
  },
};
</script>
<style scoped>
#upperAndLowerSetting {
  height: 100%;
  display: flex;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.empty{
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 250px);
  height: 100%;
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
.but {
  margin: 10px 20px 10px 0px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>