<!--访客模块--人员上下设置--人员选择-->
<template>
  <div id="personChooseModal" v-loading="loading" :element-loading-text="$t('upperAndLowerSetting.a23')">
    <!-- 头部 -->
    <div class="header">
      <a-row :gutter="16">
        <!-- 自定义条件 -->
        <a-col :span="5">
          <a-select v-model="form.condition1" style="width:100%" :allowClear="true" :placeholder="$t('upperAndLowerSetting.a22')">
            <a-select-option v-for="item in conditions1" :key="item.key">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-col>
        <!-- 条件输入框 -->
        <a-col :span="5">
          <a-input v-model="form.value1" allow-clear :placeholder="placeholder" style="width:100%"/>
        </a-col>
        <!-- 查询按钮 -->
        <a-col :span="4">
          <a-button type="primary" @click="inquire">{{ $t("upperAndLowerSetting.a8") }}</a-button>
        </a-col>
      </a-row>
    </div>
    <!-- 表格区域 -->
    <div class="main" ref="main">
      <!-- 表格标题 -->
      <my-tabletitle>{{ title }}</my-tabletitle>
      <!-- 标题 -->
      <a-table :columns="columnsPerson" :dataSource="list" :row-selection="rowSelection" :pagination="pagination" :rowKey="(record) => record.key" :scroll="size"></a-table>
    </div>
  </div>
</template>

<script>
import tabletitle from '../../components/Title/tabletitle'
import { getPersons,getpersonData } from '../../api/info'

export default {
  props: {
    personGrId: {
      type: Number,
      default:0,
    },
    isUpper :{
      type: Boolean,
    },
    listUpper:{
      type: Array,
    },
    listLower:{
      type: Array,
    },
  }, 
  data() {
    return {
      size: {  x: 700,y: 240 },
      form:{
        value1: "",
        condition1: undefined,            
      },
      conditions1:[
        {key:"managementCode",title:this.$t('upperAndLowerSetting.a6')},
        {key:"personName",title:this.$t('upperAndLowerSetting.a7')}
      ],
      loading: false,
      list: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => `${total} ${this.$t("upperAndLowerSetting.a20")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columnsPerson: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60
        },        
        {
          title: this.$t("upperAndLowerSetting.a6"),
          dataIndex: "managementCode"
        },
        {
          title: this.$t("upperAndLowerSetting.a7"),
          dataIndex: "name"
        },
        {
          title: this.$t("upperAndLowerSetting.a8"),
          dataIndex: "belong"
        }
      ]
    }
  },

  mounted(){
    this.inquire();
  },
  watch: {
    // 监听personGrId，并加载页面数据
    personGrId: function (newVal, oldVal) {  
      if(newVal>0)     
        this.inquire();
    },
  },
  computed:{
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          if(selectedRows!=null&&selectedRows.length>0){
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.$emit('selectedRows',selectedRows);//返回数据至父组件
          }
        }       
      }
    },
    // 输入框占位符
    placeholder(){
      const condition1 = this.form.condition1
      if(condition1 === 'managementCode'){
        return this.$t('upperAndLowerSetting.a6')
      }if(condition1 === 'personName'){
        return this.$t('upperAndLowerSetting.a7')
      }else{
        return this.$t('upperAndLowerSetting.a24')
      }
    },
    // 表格标题
    title(){
      const isUpper = this.isUpper
      if(isUpper){
        return this.$t('upperAndLowerSetting.a25')
      }else{
        return this.$t('upperAndLowerSetting.a26')
      }
    }
  },
  methods: {
    // 条件查询方法
    inquire() {
      this.loading = true;
      let par = {
                  managementCode: this.form.condition1=="managementCode"?this.form.value1:"",
                  personGrId: this.personGrId,
                  personName: this.form.condition1=="personName"?this.form.value1:"",
                  siteId: this.$route.query.id,
                };
      console.log("getpersonData par:",par);
      getpersonData(par)
        .then((res) => {              
          this.loading = false;
          let { data } = res;
          this.list.length=0;
          // if(this.isUpper){
            console.log("getpersonData listUpper:",this.listUpper);
            console.log("getpersonData data:",data);
            let isExist = false;                
            for(let i=0;i<data.length;i++){
              isExist = false;
              for(let j=0;j<this.listUpper.length;j++){               
                if(data[i].managementCode == this.listUpper[j].superiorCode){
                  isExist=true;
                  break;
                }                    
              }
              for(let j=0;j<this.listLower.length;j++){               
                if(data[i].managementCode == this.listLower[j].personCode){
                  isExist=true;
                  break;
                }                    
              }
              if(!isExist)
                this.list.push(data[i]);
            }
          // }
          // else{
          //   let isExist = false;                
          //   for(let i=0;i<data.length;i++){
          //     isExist = false;
          //     for(let j=0;j<this.listLower.length;j++){               
          //       if(data[i].managementCode == this.listLower[j].personCode){
          //         isExist=true;
          //         break;
          //       }                    
          //     }
          //     if(!isExist)
          //       this.list.push(data[i]);
          //   }
          // }
          if(this.list!=null && this.list.length>0){
            for(let i=0;i<this.list.length;i++){
              this.list[i].key = i+1;
              if(this.list[i].personGr2!=null&&this.list[i].personGr2.length>0)
                this.list[i].belong = this.list[i].personGr2;
              if(this.list[i].personGr3!=null&&this.list[i].personGr3.length>0)
                this.list[i].belong += "-"+this.list[i].personGr3;
              if(this.list[i].personGr4!=null&&this.list[i].personGr4.length>0)
                this.list[i].belong += "-"+this.list[i].personGr4;
              if(this.list[i].personGr5!=null&&this.list[i].personGr5.length>0)
                this.list[i].belong += "-"+this.list[i].personGr5;                    
            }
          }
          console.log("getpersonData res:",this.list);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
  },
  components: {
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
#personChooseModal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  margin-bottom: 15px;
  overflow: hidden;
}
.main {
  /* height: calc(100% - 100px); */
  width: 100%;
  min-width: 670px;
  overflow: hidden;
}
.form_row {
  display: flex;
  align-items: flex-end;  
}
.form_col {
  margin-right: 10px;
  min-width: 1px;
}
.select{
  width: 120px;
}
.datePicker{
  width: 100px;
}
input{
  width: 120px;
}
button {
  width: 100px;
  margin-right: 10px;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
</style>